export enum FBPixelTrackEvents {
  PageView = 'PageView',
  AddPaymentInfo = 'AddPaymentInfo',
  AddToCart = 'AddToCart',
  AddToWishlist = 'AddToWishlist',
  CompleteRegistration = 'CompleteRegistration',
  Contact = 'Contact',
  CustomizeProduct = 'CustomizeProduct',
  Donate = 'Donate',
  FindLocation = 'FindLocation',
  InitiateCheckout = 'InitiateCheckout',
  Lead = 'Lead',
  Purchase = 'Purchase',
  Schedule = 'Schedule',
  Search = 'Search',
  StartTrial = 'StartTrial',
  SubmitApplication = 'SubmitApplication',
  Subscribe = 'Subscribe',
  ViewContent = 'ViewContent',
}

export enum PinterestTrackEvents {
  AddToCart = 'addtocart',
  PageVisit = 'pagevisit',
  Signup = 'signup',
  Checkout = 'checkout',
  WatchVideo = 'watchvideo',
  Lead = 'lead',
  Search = 'search',
  ViewCategory = 'viewcategory',
  Custom = 'custom',
}
