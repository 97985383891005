/**
 * All possible Window message events FROM Vinoshipper.com
 */
export enum VinoshipperIframeMessageActions {
  /**
   * Update Cart ID to given value
   */
  VS_CARTID = 'vinoshipper:core:cartid',

  /**
   * Fired to indicate a page view. Important for single page apps that
   * don't have traditional page transitions.
   */
  VS_PAGE_VIEW = 'vinoshipper:page:view',

  /**
   * Change browser location to given value.
   */
  VS_HREF = 'vinoshipper:core:href',

  /**
   * Change to Style attributes
   */
  VS_STYLE = 'vinoshipper:core:style',

  /**
   * Change to Style attributes
   */
  VS_MOUNTED = 'vinoshipper:core:mounted',

   /**
   * Iframe needs to resize the height of the iFrame with the given number value.
   */
  RESIZE_HEIGHT = 'vinoshipper:resize:height',

  /**
   * Iframe requests the Cart UI to open.
   */
  CART_OPEN = 'vinoshipper:cart:open',

  /**
   * Iframe requests the Cart UI to close.
   */
  CART_CLOSE = 'vinoshipper:cart:close',

  /**
   * Contents of the cart were updated.
   */
  CART_UPDATE = 'vinoshipper:cart:update',

  /**
   * User initiated checkout procedure.
   */
  CART_CHECKOUT = 'vinoshipper:cart:checkout',

  /**
   * Login: The theme is requested to change.
   */
  LOGIN_THEME = 'vinoshipper:login:theme',

  /**
   * Login: Toggle the Menu (open or close)
   */
  LOGIN_MENU_TOGGLE = 'vinoshipper:login:menu:toggle',

  /**
   * Login: Open the Menu
   */
  LOGIN_MENU_OPEN = 'vinoshipper:login:menu:open',

  /**
   * Login: Close the Menu
   */
  LOGIN_MENU_CLOSE = 'vinoshipper:login:menu:close',

  /**
   * Club Registration: The theme is requested to change.
   */
  CLUB_REGISTRATION_CLUB_THEME = 'vinoshipper:club_registration:theme',

  /**
   * Club Registration: The user reset the club form.
   */
  CLUB_REGISTRATION_CLUB_RESET = 'vinoshipper:club_registration:reset',

  /**
   * Club Registration: Headline Changes
   */
  CLUB_REGISTRATION_HEADLINE = 'vinoshipper:club_registration:headline',

  /**
   * Club Registration: The Club was selected
   */
  CLUB_REGISTRATION_CLUB_SET = 'vinoshipper:club_registration:club-set',

  /**
   * Club Registration: Update to the list of allowed club IDs.
   */
  CLUB_REGISTRATION_CLUB_ALLOW = 'vinoshipper:club_registration:club-allow',

  /**
   * Club Registration: Update to the list of allowed club IDs.
   */
  CLUB_REGISTRATION_CLUB_DEFAULT = 'vinoshipper:club_registration:club-default',

  /**
   * Club Registration: User navigated to a new step.
   */
  CLUB_REGISTRATION_STEP = 'vinoshipper:club_registration:step',

  /**
   * Club Registration: User completed gift information
   */
  CLUB_REGISTRATION_GIFT_INFO = 'vinoshipper:club_registration:gift-info',

  /**
   * Club Registration: User navigated to a new step.
   */
  CLUB_REGISTRATION_DELIVERY_COMPLETE = 'vinoshipper:club_registration:delivery:complete',

  /**
   * Club Registration: Credit Card Payment - Success
   */
  CLUB_REGISTRATION_PAYMENT_SUCCESS = 'vinoshipper:club_registration:payment:success',

  /**
   * Club Registration: Credit Card Payment - Failure
   */
  CLUB_REGISTRATION_PAYMENT_FAILURE = 'vinoshipper:club_registration:payment:failure',

  /**
   * Club Registration: Personal Information
   */
  CLUB_REGISTRATION_PERSONAL_INFO = 'vinoshipper:club_registration:personal_info',

  /**
   * Club Registration: Personal Information
   */
  CLUB_REGISTRATION_COMPLETE_FORM = 'vinoshipper:club_registration:complete_form',

  /**
   * Club Registration: Age Verification Attempt Failed
   */
  CLUB_REGISTRATION_AV_ATTEMPT_FAIL = 'vinoshipper:club_registration:age_verification:attempt_fail',

  /**
   * Club Registration: Age Verification Attempt made on Idology
   */
  CLUB_REGISTRATION_AV_ATTEMPT_IDOLOGY = 'vinoshipper:club_registration:age_verification:attempt_idology',

  /**
   * Club Registration: Club registration needs email verify
   */
  CLUB_REGISTRATION_TO_VERIFY = 'vinoshipper:club_registration:verify',

  /**
   * Club Registration: Club registration is complete.
   */
  CLUB_REGISTRATION_COMPLETE = 'vinoshipper:club_registration:complete',

  /**
   * Add a product to the cart; cumulative quantity is added.
  */
  PRODUCT_ADD = 'vinoshipper:product:add',

  /**
   * Update a Product; ignores existing quantity.
   * Sending `0` deletes product from card.
   */
  PRODUCT_UPDATE = 'vinoshipper:product:update',

  /**
   * Remove a product to the cart.
   */
  PRODUCT_REMOVE = 'vinoshipper:product:remove',

}

/**
 * All WindowMessage events produced by the injector.
 */
export enum VinoshipperInjectorMessageActions {
  /**
   * The Vinoshipper Injector is loaded.
   */
  VS_LOADED = 'vinoshipper:loaded',

  /**
   * The Vinoshipper Injector is configured.
   */
  VS_CONFIGURED = 'vinoshipper:configured',

  /**
   * The Vinoshipper Injector is rendered.
   */
  VS_RENDERED = 'vinoshipper:rendered',

  /**
   * The Vinoshipper Injector is destroyed.
   */
  VS_DESTROYED = 'vinoshipper:destroyed',

  /**
   * The parent window fired a "pageshow" event.
   */
  VS_CLIENT_PAGE_SHOW = 'vinoshipper:client:pageshow',

  /**
   * Cart: Started the process of opening.
   */
  VS_CART_OPENING = 'vinoshipper:cart:opening',

  /**
   * Cart: Finished the process of opening.
   */
  VS_CART_OPENED = 'vinoshipper:cart:opened',

  /**
   * Cart: Started the process of closing.
   */
  VS_CART_CLOSING = 'vinoshipper:cart:closing',

  /**
   * Cart: Finished the process of closing.
   */
  VS_CART_CLOSED = 'vinoshipper:cart:closed',

  /**
   * Add a product to the cart, cumulative to current quantity.
   * This only ADDS to the existing number. Use update for all other cases, including remove.
   */
  VS_PRODUCT_ADD = 'vinoshipper:cart:add',

  /**
   * An update to the cart was posted.
   * This includes (and not limited to) add, remove, or updated products.
   */
  VS_PRODUCT_UPDATE = 'vinoshipper:cart:update',

  /**
   * Remove a product to the cart.
   */
  VS_PRODUCT_REMOVE = 'vinoshipper:cart:remove',

  /**
   * User initiated checkout procedure.
   */
  VS_CART_CHECKOUT = 'vinoshipper:cart:checkout',

}

export enum VinoshipperInjectorDeliveryOption {
  PICKUP = 'PICKUP',
  SHIPMENT = 'SHIPMENT',
}
