/**
 * Detect and report on what CMS system is used, if any.
 */
export default class VSCms {

  current : string | null = null

  constructor () {
  }

  detect () {
    if (this.isSquarespace()) {
      this.current = 'squarespace'
    } else if (this.isWix()) {
      this.current = 'wix'
    } else if (this.isDuda()) {
      this.current = 'duda'
    } else if (this.isWebflow()) {
      this.current = 'webflow'
    } else if (this.isShopify()) {
      this.current = 'shopify'
    } else {
      this.current = this.getWordpress()
    }
  }

  isSquarespace () {
    return (typeof window.Static === 'object')
  }

  isWix () {
    return (
      typeof window.wixTagManager === 'object' ||
      typeof window.wixPerformanceMeasurements === 'object'
    )
  }

  isDuda () {
    return (typeof window.isDudaone === 'boolean')
  }

  isWebflow () {
    return (typeof window.Webflow === 'object')
  }

  isShopify () {
    return (typeof window.Shopify === 'object')
  }

  getWordpress () : string | null {
    let contents : string | null = null
    const metaGenerators = document.querySelectorAll('meta[name="generator"]')
    metaGenerators.forEach((item) => {
      if (item.hasAttribute('content')) {
        const tempContents = item.getAttribute('content')?.toLowerCase().trim() ?? null
        if (tempContents?.startsWith('wordpress')) {
          contents = tempContents.replace(' ', ':')
        } else if (!contents && tempContents?.startsWith('site kit by google')) {
          contents = 'wordpress:site-kit-by-google'
        } else if (!contents && tempContents?.startsWith('woo framework')) {
          contents = 'wordpress:woo'
        }
      }
    })
    return contents
  }

}
