export enum InjectorVersion {
  V3 = 'V3',
  V4 = 'V4',
}

export enum PixelType {
  GA = 'GA',
  GA4 = 'GA4',
  GTM = 'GTM',
  FB = 'FB',
  PINTEREST = 'PINTEREST',
}
