import { Vinoshipper } from '@/base/vs-core'

const errorGeneral = new Error('Vinoshipper: There was a problem loading the injector.')
const errorMultiVino = new Error('Vinoshipper: Loaded more than once.')
const styleHeader = 'background-color: red; color: white; font-style: italic; border: 5px solid red; font-size: 1.5em;padding:2px';
const styleDescription = 'background-color: darkred; color: white;padding:2px';

try {
  if (!window.Vinoshipper) {
    window.Vinoshipper = new Vinoshipper()
    window.document.dispatchEvent(new CustomEvent('vinoshipper:loaded'))
  } else {
    throw errorMultiVino
  }
} catch (error) {
  if (error === errorMultiVino) {
    console.log("%cVinoshipper: Loaded more than once.", styleHeader)
    console.log("%cThis website is calling of the Vinoshipper Injector Javascript more than once. Please remove all instances of Vinoshipper Injector V4 except for one. For more information, visit:\nhttps://vinoshipper.freshdesk.com/support/solutions/articles/9000203795-integration-resources", styleDescription)
    console.error(error)
  } else {
    console.error(errorGeneral, error)
  }
}
