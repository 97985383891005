import type { LineItem } from "../../../@types/cart"
import type { Pixel } from "../../../@types/vs-init"
import { PixelType } from "../../../@types/vs-init.enum"
import { VinoshipperIframeMessageActions, VinoshipperInjectorMessageActions } from "../../../@types/vs-events.enum"
import { VinoshipperIframeMessage, VinoshipperIframeMessageActions_CLUB_REGISTRATION_AV_ATTEMPT_FAIL, VinoshipperIframeMessageActions_CLUB_REGISTRATION_CLUB_SET, VinoshipperIframeMessageActions_CLUB_REGISTRATION_COMPLETE_FORM, VinoshipperIframeMessageActions_CLUB_REGISTRATION_DELIVERY_COMPLETE, VinoshipperIframeMessageActions_CLUB_REGISTRATION_GIFT_INFO, VinoshipperIframeMessageActions_CLUB_REGISTRATION_PAYMENT_FAILURE, VinoshipperIframeMessageActions_CLUB_REGISTRATION_PAYMENT_SUCCESS, VinoshipperIframeMessageActions_CLUB_REGISTRATION_PERSONAL_INFO, VinoshipperIframeMessageActions_VS_PAGE_VIEW, VinoshipperIframeMessageProductAdd } from "../../../@types/vs-events"

import { VSAnalyticsGoogle } from "@/base/analytics/google"
import { VSAnalyticsFacebook } from "@/base/analytics/facebook"
import { VSAnalyticsPinterest } from "@/base/analytics/pinterest"
import { debugLog } from "@/base/vs-log"

export default class VSAnalytics {
  skipAnalytics = false
  propertyGA : string[] = []
  propertyGA4 : string[] = []
  propertyGTM : string[] = []
  propertyPinterest : string[] = []
  propertyMeta : string[] = []
  facebook : VSAnalyticsFacebook | undefined = undefined
  google : VSAnalyticsGoogle | undefined = undefined
  pinterest : VSAnalyticsPinterest | undefined = undefined

  constructor (skipAnalytics : boolean | undefined) {
    if (!skipAnalytics) {
      debugLog('VSAnalytics :: Enabled')
      this.skipAnalytics = false
      this.facebook = new VSAnalyticsFacebook()
      this.google = new VSAnalyticsGoogle()
      this.pinterest = new VSAnalyticsPinterest()
      this.handlers()
    } else {
      this.skipAnalytics = true
    }
  }

  handlers () {

    // VS Page View
    window.addEventListener(VinoshipperIframeMessageActions.VS_PAGE_VIEW, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessageActions_VS_PAGE_VIEW>
      this.google?.sendEvent('page_view', {
        page_title: message.detail.pageName
      })
      if (this.propertyGA.length) {
        this.google?.uaSendEventPageView(message.detail.pageName)
      }
    }, false)

    // Product Add
    window.addEventListener(VinoshipperIframeMessageActions.PRODUCT_ADD, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<VinoshipperIframeMessageProductAdd>>

      let quantity = message.detail.value.qty ?? 0
      const price = message.detail.value.product?.price ?? 0
      if (quantity === 0) {
        quantity = 1
      }

      message.detail.value.qty = quantity

      this.google?.sendEventAddToCart(message.detail.value)

      this.facebook?.sendEventAddToCart({
        value: (price * quantity),
        currency: 'USD',
        content_name: message.detail.value.product?.name ?? undefined,
        content_ids: [message.detail.value.id.toString()],
        contents: [
          {
            id: message.detail.value.id.toString(),
            quantity: quantity,
          }
        ],
        content_type: 'product',
      })

      this.pinterest?.sendEventAddToCart({
        value: (price * quantity),
        currency: 'USD',
        line_items: [
          {
            product_id: message.detail.value.id.toString(),
            product_quantity: quantity,
            product_name: message.detail.value.product?.name ?? undefined,
          }
        ],
      })

    })

    // Product Remove
    window.addEventListener(VinoshipperInjectorMessageActions.VS_PRODUCT_REMOVE, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<LineItem>>

      this.google?.sendEventRemoveFromCart(message.detail.value)

    })

    // Clubs: Select Club
    window.addEventListener(VinoshipperIframeMessageActions.CLUB_REGISTRATION_CLUB_SET, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<VinoshipperIframeMessageActions_CLUB_REGISTRATION_CLUB_SET>>
      this.google?.sendEvent('club_registration_club_set', {
        club_id: message.detail.value.id,
        club_name: message.detail.value.name,
        club_gift: message.detail.value.gift,
      })
    }, false)

    // Clubs: Gift Options Complete
    window.addEventListener(VinoshipperIframeMessageActions.CLUB_REGISTRATION_GIFT_INFO, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<VinoshipperIframeMessageActions_CLUB_REGISTRATION_GIFT_INFO>>
      this.google?.sendEvent('club_registration_delivery_complete', {
        club_id: message.detail.value.club.id,
        club_name: message.detail.value.club.name,
        club_gift: true,
        club_duration: message.detail.value.releasesRemaining,
      })
    }, false)

    // Clubs: Delivery Options Complete
    window.addEventListener(VinoshipperIframeMessageActions.CLUB_REGISTRATION_DELIVERY_COMPLETE, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<VinoshipperIframeMessageActions_CLUB_REGISTRATION_DELIVERY_COMPLETE>>
      this.google?.sendEvent('club_registration_delivery_complete', {
        club_id: message.detail.value.club.id,
        club_name: message.detail.value.club.name,
        club_gift: message.detail.value.isGift,
        club_delivery_option: message.detail.value.deliveryOption,
      })
    }, false)

    // Clubs: Payment Failure
    window.addEventListener(VinoshipperIframeMessageActions.CLUB_REGISTRATION_PAYMENT_FAILURE, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<VinoshipperIframeMessageActions_CLUB_REGISTRATION_PAYMENT_FAILURE>>
      debugLog('VSAnalytics :: Payment Failure', message)
      this.google?.sendEvent('club_registration_payment_failure', {
        error_field: message.detail.value.field,
        error_code: message.detail.value.code,
        error_description: message.detail.value.description,
        error_description_short: message.detail.value.shortDescription,
      })
    }, false)

    // Clubs: Payment Success
    window.addEventListener(VinoshipperIframeMessageActions.CLUB_REGISTRATION_PAYMENT_SUCCESS, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<VinoshipperIframeMessageActions_CLUB_REGISTRATION_PAYMENT_SUCCESS>>
      debugLog('VSAnalytics :: Payment Success', message)
      this.facebook?.sendEventAddPaymentInfo({})
      this.google?.sendEvent('club_registration_payment_success', message)
    }, false)

    // Clubs: AV Attempt Fail
    window.addEventListener(VinoshipperIframeMessageActions.CLUB_REGISTRATION_AV_ATTEMPT_FAIL, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<VinoshipperIframeMessageActions_CLUB_REGISTRATION_AV_ATTEMPT_FAIL>>
      debugLog('VSAnalytics :: Age Verification Fail', message)
      this.google?.sendEvent('club_registration_av_attempt_fail', {
        av_fail_count: message.detail.value.count,
      })
    }, false)

    // Clubs: Personal Info
    window.addEventListener(VinoshipperIframeMessageActions.CLUB_REGISTRATION_PERSONAL_INFO, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<VinoshipperIframeMessageActions_CLUB_REGISTRATION_PERSONAL_INFO>>
      this.google?.sendUserId(message.detail.value.email, [...this.propertyGA4, ...this.propertyGTM])
      this.google?.sendEvent('club_registration_personal_info', {
        user_id: message.detail.value.email,
      })

      // DEPRECATED - Producers need to move away from this field.
      this.google?.sendEvent('VSClubSignUpPersonalInfo', {
        vs_club_member_email: message.detail.value.email,
        vs_club_member_first_name: message.detail.value.firstName,
        vs_club_member_last_name: message.detail.value.lastName,
      })
    }, false)

    // Clubs: Complete Form
    window.addEventListener(VinoshipperIframeMessageActions.CLUB_REGISTRATION_COMPLETE_FORM, (event : Event) => {
      const message = event as CustomEvent<VinoshipperIframeMessage<VinoshipperIframeMessageActions_CLUB_REGISTRATION_COMPLETE_FORM>>

      this.google?.sendUserId(message.detail.value.member.email, [...this.propertyGA4, ...this.propertyGTM])

      this.google?.sendEvent('club_registration_complete_form', {
        user_id: message.detail.value.member.email,
        club_id: message.detail.value.club?.id,
        club_name: message.detail.value.club?.name,
        club_gift: message.detail.value.isGift,
      })

      // DEPRECATED - Producers need to move away from this field.
      this.google?.sendEvent('VSClubSignUpPersonalInfo', {
        vs_club_id: message.detail.value.club?.id,
        vs_club_name: message.detail.value.club?.name,
        vs_club_member_email: message.detail.value.member.email,
        vs_club_member_first_name: message.detail.value.member.firstName,
        vs_club_member_last_name: message.detail.value.member.lastName,
      })

      this.facebook?.sendEventCompleteRegistration({
        content_name: message.detail.value.club?.id.toString(),
        status: true,
        currency: 'USD',
        value: 1,
      })

      this.pinterest?.sendEventCompleteRegistration({
        lead_type: message.detail.value.club?.id.toString(),
      })

    }, false)

    // Clubs: Manual Form Reset
    window.addEventListener(VinoshipperIframeMessageActions.CLUB_REGISTRATION_CLUB_RESET, () => {
      this.google?.sendEvent('club_registration_club_reset', {})
    }, false)

    // Cart View
    window.addEventListener(VinoshipperInjectorMessageActions.VS_CART_OPENED, (event : Event) => {
      debugLog('VSAnalytics :: View Cart', event)
      const cart = window.Vinoshipper.getCart()
      if (cart) {
        this.google?.sendCartView(cart)
      }
    })

    // Cart Checkout
    window.addEventListener(VinoshipperIframeMessageActions.CART_CHECKOUT, (event : Event) => {
      debugLog('VSAnalytics :: Cart Checkout', event)
      const cart = window.Vinoshipper.getCart()
      if (cart) {
        this.google?.sendBeginCheckout(cart)
      }
    })
  }

  pixelsUpdate (pixels : Pixel[] | undefined) {
    // Each time the cart is updated. If it's a new ID, we have to initialize.
    if (this.skipAnalytics) {
      return
    }

    pixels?.forEach((pixel) => {
      if (pixel.producerId === window.Vinoshipper.getId()) {
        if (
          pixel.type === PixelType.GA4 &&
          !this.propertyGA4.includes(pixel.trackingId)
        ) {
          this.propertyGA4.push(pixel.trackingId)
          debugLog(`VSAnalytics :: Google Analytics V4 : ${pixel.trackingId}`)
        }

        if (
          pixel.type === PixelType.GTM &&
          !this.propertyGTM.includes(pixel.trackingId)
        ) {
          this.propertyGTM.push(pixel.trackingId)
          debugLog(`VSAnalytics :: Google Tag Manager : ${pixel.trackingId}`)
        }

        if (
          pixel.type === PixelType.GA &&
          !this.propertyGA.includes(pixel.trackingId)
        ) {
          this.propertyGA.push(pixel.trackingId)
          debugLog(`VSAnalytics :: Google Universal Analytics (deprecated) : ${pixel.trackingId}`)
          this.google?.uaWarning()
        }

        if (
          pixel.type === PixelType.PINTEREST &&
          !this.propertyPinterest.includes(pixel.trackingId)
        ) {
          this.propertyPinterest.push(pixel.trackingId)
          debugLog(`VSAnalytics :: Pinterest : ${pixel.trackingId}`)
        }

        if (
          pixel.type === PixelType.FB &&
          !this.propertyMeta.includes(pixel.trackingId)
        ) {
          this.propertyMeta.push(pixel.trackingId)
          debugLog(`VSAnalytics :: Meta Pixel : ${pixel.trackingId}`)
        }

      }
    })
  }

  async linkGenerate (url : URL, includeRet = true) : Promise<URL> {
    // Returns params needed for cross-domain linking.
    const urlCurrentParams = new URLSearchParams(window.location.search)
    if (urlCurrentParams.has('sscid')) {
      url.searchParams.append('sscid', urlCurrentParams.get('sscid') ?? '')
    }

    // Impact Analytics
    if (urlCurrentParams.has('_ics')) {
      url.searchParams.append('_ics', urlCurrentParams.get('_ics') ?? '')
    }
    if (urlCurrentParams.has('irclickid')) {
      url.searchParams.append('irclickid', urlCurrentParams.get('irclickid') ?? '')
    }

    // Return URL
    if (!url.searchParams.has('ret') && includeRet) {
      url.searchParams.append('ret', window.location.href)
    }

    let googleTargetId : string | null = null
    let uaPropertyOnly : string | undefined = undefined

    // NOTE: GTM is not compatible with cross-domain parameters.
    if (this.propertyGA4.length) {
      googleTargetId = this.propertyGA4[0]
    } else if (this.propertyGA.length) {
      googleTargetId = this.propertyGA[0]
      uaPropertyOnly = this.propertyGA[0]
    }

    if (this.google && googleTargetId) {
      return this.google.linkGenerateParams(googleTargetId, uaPropertyOnly)
        .then((result) => {
          for (const [paramName, value] of result) {
            url.searchParams.append(paramName, value)
          }
          return url
        })
        .catch(() => {
          return url
        })
    } else {
      return Promise.resolve(url)
    }
  }

  hasAnalytics () {
    return (
      (
        this.propertyGA4.length > 0 ||
        this.propertyGTM.length > 0 ||
        this.propertyMeta.length > 0 ||
        this.propertyPinterest.length > 0
      ) && !this.skipAnalytics
    )
  }

}
