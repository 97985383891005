import { debugLog } from "@/base/vs-log"

export class VSAnalyticsPinterest {
  constructor () {
    if (window.pintrk) {
      debugLog('VSAnalytics :: Pinterest :: Available')
    }
  }

  sendEventAddToCart (contents: pinterest.CoreParameters) {
    if (window.pintrk) {
      debugLog(`VSAnalytics :: Pinterest :: AddToCart`, contents)
      window.pintrk('track', 'addtocart', contents)
    }
  }

  sendEventCompleteRegistration (contents: pinterest.CoreParameters) {
    if (window.pintrk) {
      debugLog(`VSAnalytics :: Pinterest :: SignUp`, contents)
      window.pintrk('track', 'signup', contents)
    }
  }

}
