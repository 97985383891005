export class VSLog {
  readonly history: string[]
  show: boolean

  constructor (showConsole = false) {
    this.history = []
    this.show = showConsole
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add (input : any) {
    this.history.push(input)
    if (this.show) {
      console.log(input)
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debugLog(message?: any, ...optionalParams: any[]) {
  if (window.Vinoshipper && window.Vinoshipper.isDebug()) {
    if (optionalParams.length > 0) {
      console.log(message, optionalParams)
    } else {
      console.log(message)
    }
  }
}
