import { FBPixelTrackEvents } from "../../../@types/vs-analytics.enum"
import { debugLog } from "@/base/vs-log"

export class VSAnalyticsFacebook {
  constructor () {
    debugLog('VSAnalytics :: Facebook :: Available')
  }

  sendEvent (
    event : FBPixelTrackEvents,
    params : facebook.Pixel.ViewContentParameters | facebook.Pixel.CompleteRegistrationParameters | facebook.Pixel.AddPaymentInfoParameters | undefined = undefined
  ) {
    if (window.fbq) {
      debugLog(`VSAnalytics ::FB::${event}`, params)
      let eventType = 'trackCustom'

      if (Object.values(FBPixelTrackEvents).includes(event)) {
        eventType = 'track'
      }

      if (params) {
        window.fbq(eventType, event, params)
      } else {
        window.fbq(eventType, event)
      }
    }
  }

  sendEventAddToCart (contents : facebook.Pixel.ViewContentParameters) {
    this.sendEvent(FBPixelTrackEvents.AddToCart, contents)
  }

  sendEventAddPaymentInfo (contents : facebook.Pixel.AddPaymentInfoParameters) {
    this.sendEvent(FBPixelTrackEvents.AddPaymentInfo, contents)
  }

  sendEventCompleteRegistration (contents : facebook.Pixel.CompleteRegistrationParameters) {
    this.sendEvent(FBPixelTrackEvents.CompleteRegistration, contents)
  }

}
