import { VinoshipperIframeMessage } from '../../@types/vs-events'
import { InjectorConfigForm, InjectorConfigResponse, InjectorDeliveryOptions, VinoshipperConfig, Pixel, vsThemeNames } from '../../@types/vs-init'
import { VinoshipperIframeMessageActions, VinoshipperInjectorMessageActions } from '../../@types/vs-events.enum'
import { Cart, LineItem, VSCartOnProductAddOptions } from '../../@types/cart'
import { InjectorVersion } from '../../@types/vs-init.enum'

import VSProductCatalog from '@/modules/products'
import VSProductItem from '@/modules/product-item'
import VSClubRegistration from '@/modules/club-registration'
import VSAddToCart from '@/modules/add'
import VSCart from '@/modules/cart'
import VSAvailableIn from '@/modules/available'
import VSLogin from '@/modules/login'
import VSProductCatalogV3 from '@/modules/products-v3'
import VSAnnouncement from '@/modules/announcement'
import VSAnalytics from '@/base/analytics'
import { VSAnalyticsGoogle } from '@/base/analytics/google'
import VSCms from '@/base/vs-cms'

export class Vinoshipper {
  readonly version: string
  readonly dataPrefix = 'data-vs-'
  private producerId: number | null | 'catalog' = null
  private loaded: boolean = false
  private configured: boolean = false
  private rendered: boolean = false
  private pageShowCount: number = 0
  private _cartId: string | null = null
  private _cart: Cart | null = null
  private renderedObjects: (VSClubRegistration | VSProductCatalog | VSProductItem | VSProductCatalogV3 | VSAddToCart | VSAvailableIn | VSLogin | VSAnnouncement)[] = []
  private _cartObj: VSCart | null = null
  private _config: VinoshipperConfig
  private _pixels: Pixel[] = []
  private _styleHeader = 'background-color:red;color:white;font-style:italic;font-size:1.5em;padding:2px;'
  private _styleDescription = 'font-size:1em;line-height:1.2;'
  analytics: VSAnalytics | null = null
  private _cms = new VSCms()
  GTM = new VSAnalyticsGoogle()
  private _deliveryOptions : InjectorDeliveryOptions
  private _announcement : string | null = null
  private readonly _vsLoginAllowDomains = [
    'vinoshipper.com',
    'zlminc.dev',
    'zlminc.com',
  ]

  constructor () {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    this.version = require('../../package.json').version
    this._deliveryOptions = {
      allowsPickup: false,
      allowsLocalDelivery: false,
      shipsTo: [],
      restrictedShipsTo: [],
    }
    this._config = this.settingsDeclare(undefined)
  }

  async init (producerId : number, config : VinoshipperConfig | undefined) {
    if (this.loaded) {
      this.errorVinoshipperAlreadyLoaded()
      return Promise.resolve()
    }

    const injectorForm : InjectorConfigForm = {
      version: InjectorVersion.V4,
      producerId: null,
      catalogId: null,
      config: config ?? null,
      context: {
        cms: null,
        plugin: null,
      },
    }

    if (typeof producerId === 'number') {
      this.producerId = producerId
      injectorForm.producerId = producerId
    } else if (producerId === 'catalog') {
      this.producerId = 'catalog'
      injectorForm.catalogId = 'catalog'
    } else if (typeof producerId === 'string' && !isNaN(parseInt(producerId))) {
      this.producerId = parseInt(producerId)
    } else if (typeof producerId === 'string' && isNaN(parseInt(producerId))) {
      this.producerId = 'catalog'
      injectorForm.catalogId = producerId
    } else {
      // The init is misconfigured, so return an error.
      throw new Error('Vinoshipper: You must define the producer ID number in the Vinoshipper.init() function.')
    }

    if (typeof config?.vsPlugin === 'string') {
      injectorForm.context.plugin = config.vsPlugin
    }

    this._cms.detect()
    if (this._cms.current) {
      injectorForm.context.cms = this._cms.current
    }

    if (typeof config === 'object') {
      // An object with settings.
      this._config = this.settingsDeclareCore(config)
    }

    const injectorInitUrl = new URL('/api/v3/injector', window.Vinoshipper.getServer())
    const injectorData : RequestInit = {
      method: 'POST',
      mode: 'cors',
      headers: {
          "Content-Type": "application/json",
      },
      cache: "no-cache",
      body: JSON.stringify(injectorForm),
    }

    return fetch(injectorInitUrl, injectorData)
      .then(async (response) => {
        try {
          const results : InjectorConfigResponse = await response.json()
          this._announcement = results.announcement ?? null
          this._deliveryOptions = results.deliveryOptions
          this._pixels = results.pixels
          if (config) {
            // Combine between server and local configs.
            Object.entries(results.config).forEach(([key, value]) => {
              config[key as keyof VinoshipperConfig] = value
            })
            this._config = this.settingsDeclare(config)
          } else {
            this._config = this.settingsDeclare(results.config)
          }
        } catch {
          this._config = this.settingsDeclare(config)
          if (this._config.debug) {
            console.log('VSInit: Could not connect to main server.')
          }
        }
      })
      .catch(() => {
        this._config = this.settingsDeclare(config)
        if (this._config.debug) {
          console.log('VSInit: Could not connect to main server.')
        }
      })
      .finally(() => {
        return this.initPost()
      })
  }

  private async initPost () {

    if (this._config.debug) {
      console.log('VS Config:', this._config)
    }

    this.configured = true
    window.document.dispatchEvent(new CustomEvent(VinoshipperInjectorMessageActions.VS_CONFIGURED))

    this.analytics = new VSAnalytics(this._config.skipAnalytics)

    window.addEventListener('message', (messageEvent : MessageEvent) => {
      this.onMessage(messageEvent)
    }, false)

    window.addEventListener('pageshow', () => {
      let value = false
      if (this.pageShowCount > 0) {
        value = true
      }
      window.postMessage({
        instanceId: 'client',
        action: VinoshipperInjectorMessageActions.VS_CLIENT_PAGE_SHOW,
        value: value,
      }, {
        targetOrigin: '*',
      })
      this.pageShowCount++
    }, false)

    window.addEventListener('mercury:load', () => {
      // This block is for Squarespace's Mercury platform, which makes page loads an Ajax call.
      this.render()
      if (this.isDebug()) {
        console.info('Vinoshipper :: mercury:load')
      }
    })
    window.addEventListener('mercury:navigate', () => {
      // This block is for Squarespace's Mercury platform, which makes page makes a navigate event.
      this.render()
      if (this.isDebug()) {
        console.info('Vinoshipper :: mercury:navigate')
      }
    })
    if (typeof window.Squarespace === 'object') {
      window.Squarespace.onInitialize(window.Y, () => {
        this.render()
      })
      if (this.isDebug()) {
        console.info('Vinoshipper :: Squarespace :: onInitialize()')
      }
    }

    if (!this._config.skipAnalytics) {
      this.analytics?.pixelsUpdate(this._pixels)
    }

    this.loaded = true
    return Promise.allSettled([
      this.cartInit(),
      this.renderInit(true),
    ])
      .then(() => {
        if (this.isDebug()) {
          console.info('Vinoshipper :: init() Producer ID:', this.producerId)
        }
      })

  }

  private defaultConfig () : VinoshipperConfig {
    return {
      debug: false,
      autoRender: true,
      autoCart: true,
      skipAnalytics: false,
      theme: null,
      tooltips: true,
      cartPosition: 'end',
      cartButton: true,
      addToCartStyle: true,
      productCatalogStyle: true,
      productCatalogAvailable: true,
      productCatalogV3: false,
      productItemImage: true,
      v3CssProductList: null,
      availableInStyle: true,
      // availableInTooltips: true, // Leave blank in order to fallback to `tooltips`.
      announcementStyle: true,
      allowMultiProducer: false,
      listenerDebounce: 500,
      iframeStyle: null,
      vsServer: 'https://vinoshipper.com',
      vsPlugin: null,
    }
  }

  /**
   * For use only to define basic connection settings to establish the Init.
   */
  private settingsDeclareCore (config : VinoshipperConfig | undefined) : VinoshipperConfig {
    const returnConfig = this.defaultConfig()

    // Turn on debug information.
    if (typeof config?.debug === 'boolean') {
      returnConfig.debug = config.debug
    }

    // Explicitly state a different server.
    if (typeof config?.vsServer === 'string') {
      returnConfig.vsServer = config.vsServer
    }

    return returnConfig
  }

  private settingsDeclare (config : VinoshipperConfig | undefined) : VinoshipperConfig {
    const returnConfig = this.defaultConfig()

    // Turn on debug information.
    if (typeof config?.debug === 'boolean') {
      returnConfig.debug = config.debug
    }

    // Explicitly state a different server.
    if (typeof config?.vsServer === 'string') {
      returnConfig.vsServer = config.vsServer
    }

    // Alumni Program Offering Metadata
    if (typeof config?.vsProgramOffering === 'string') {
      returnConfig.vsProgramOffering = config.vsProgramOffering
    }

    // Skip all Analytics calls.
    if (typeof config?.skipAnalytics === 'boolean') {
      returnConfig.skipAnalytics = config.skipAnalytics
    }

    // Automatically render all components.
    if (typeof config?.autoRender === 'boolean') {
      returnConfig.autoRender = config.autoRender
    }

    // Set the theme for all components.
    if (typeof config?.theme === 'string') {
      returnConfig.theme = this.themeNormalize(config.theme)
    }

    // Set the tooltips for all components.
    if (typeof config?.tooltips === 'boolean') {
      returnConfig.tooltips = config.tooltips
    }

    // Cart: Position of cart pop-out
    if (typeof config?.cartPosition === 'string') {
      returnConfig.cartPosition = config.cartPosition
    }

    // Cart: Show/hide the cart button.
    if (typeof config?.cartButton === 'boolean') {
      returnConfig.cartButton = config.cartButton
    }

    // Cart: Load stylesheets for Add To Cart buttons.
    if (typeof config?.addToCartStyle === 'boolean') {
      returnConfig.addToCartStyle = config.addToCartStyle
    }

    // Cart: Allow multi-producer mode.
    if (typeof config?.allowMultiProducer === 'boolean') {
      returnConfig.allowMultiProducer = config.allowMultiProducer
    }

    // Product Catalog: Load stylesheets for Product Catalogs.
    if (typeof config?.productCatalogStyle === 'boolean') {
      returnConfig.productCatalogStyle = config.productCatalogStyle
    }

    // Product Catalog: Display "Available In" component automatically.
    if (typeof config?.productCatalogAvailable === 'boolean') {
      returnConfig.productCatalogAvailable = config.productCatalogAvailable
    }

    // Product Catalog: Display "Available In" component automatically.
    if (typeof config?.productCatalogAnnouncement === 'boolean') {
      returnConfig.productCatalogAnnouncement = config.productCatalogAnnouncement
    }

    // Product Catalog: Use "V3" / iFrame version
    if (typeof config?.productCatalogV3 === 'boolean') {
      returnConfig.productCatalogV3 = config.productCatalogV3
    }

    // Product Catalog: Load given CSS for V3 Product Catalog.
    if (typeof config?.v3CssProductList === 'string') {
      returnConfig.v3CssProductList = config.v3CssProductList.trim()
    }

    // Product Item: Display the product image.
    if (typeof config?.productItemImage === 'boolean') {
      returnConfig.productItemImage = config.productItemImage
    }

    // Available In: Load stylesheets for Available In components.
    if (typeof config?.availableInStyle === 'boolean') {
      returnConfig.availableInStyle = config.availableInStyle
    }

    // Available In: Load stylesheets for Available In components.
    if (typeof config?.availableInTooltips === 'boolean') {
      returnConfig.availableInTooltips = config.availableInTooltips
    }

    // Announcement: Load stylesheets for Announcement components.
    if (typeof config?.announcementStyle === 'boolean') {
      returnConfig.announcementStyle = config.announcementStyle
    }

    // Cart: Automatically initialize and render the cart.
    if (typeof config?.autoCart === 'boolean') {
      returnConfig.autoCart = config.autoCart
    } else if (returnConfig.autoRender) {
      returnConfig.autoCart = true
    }

    // Cart: Automatically initialize and render the cart.
    if (typeof config?.cartUrlParams === 'function') {
      returnConfig.cartUrlParams = config.cartUrlParams
    } else {
      returnConfig.cartUrlParams = undefined
    }

    // Core: Listener Debounce
    if (typeof config?.listenerDebounce === 'number') {
      returnConfig.listenerDebounce = config.listenerDebounce
    } else {
      returnConfig.listenerDebounce = 500
    }

    // iFrame Styles.
    if (typeof config?.iframeStyle === 'string') {
      const errorProtocol = new Error('Vinoshipper: iframeStyle must be served from a secure URL.')
      const iframeStyle = config.iframeStyle.trim()
      if (iframeStyle.startsWith('http://')) {
        console.error(errorProtocol)
      } else if (iframeStyle.startsWith('https://')) {
        returnConfig.iframeStyle = config.iframeStyle
      } else if (window.location.protocol !== 'https:') {
        console.error(errorProtocol)
      } else {
        const newURL = new URL(config.iframeStyle, window.location.origin)
        returnConfig.iframeStyle = newURL.href
      }
    }

    return returnConfig
  }

  private async initCartData (cartId : string | null) {
    let cartUrl =  new URL(`/api/v3/cart/get-or-create`, this.getServer())
    let cartMethod = 'POST'
    const cartPost = {
      producerId: undefined as string | undefined
    }

    if (cartId) {
      cartUrl =  new URL(`/api/v3/cart/${cartId}`, this.getServer())
      cartMethod = 'GET'
    } else if (this._cartId) {
      cartUrl =  new URL(`/api/v3/cart/${this._cartId}`, this.getServer())
      cartMethod = 'GET'
    } else if (this._cart?.cartId) {
      cartUrl =  new URL(`/api/v3/cart/${this._cart.cartId}`, this.getServer())
      cartMethod = 'GET'
    } else if (this.producerId) {
      cartPost.producerId = this.producerId.toString()
    }

    let fetcher = fetch(cartUrl, {
      method: cartMethod,
      cache: 'no-cache',
    })
    if (cartMethod === 'POST') {
      fetcher = fetch(cartUrl, {
        method: cartMethod,
        cache: 'no-cache',
        body: cartPost ? JSON.stringify(cartPost) : undefined,
      })
    }

    return fetcher
    .then((response) => {
      if (!response.ok) {
        throw new Error('Vinoshipper :: Cart Data could not be obtained.');
      }
      return response.json()
    })
    .then((data : Cart) => {
      this.updateCart(data)
      const message : VinoshipperIframeMessage = {
        instanceId: 'cart',
        action: VinoshipperIframeMessageActions.CART_UPDATE,
        value: this._cart,
      }
      const newMessage : MessageEvent = new MessageEvent(
        VinoshipperIframeMessageActions.CART_UPDATE,
        {
          data: message
        }
      )
      this._cartObj?.onMessage(newMessage)
      return this._cart
    })
    .catch((errors) => {
      console.error(new Error('Vinoshipper: A problem was encountered when obtaining cart data.'), errors)
    })
  }

  private async renderInit (useSettings = true) {
    return Promise.allSettled([
      (!useSettings || (useSettings && this._config.autoCart)) ? this.initVsCart() : Promise.resolve(),
      (!useSettings || (useSettings && this._config.autoRender)) ? this.initVsAnnouncement() : Promise.resolve(),
      (!useSettings || (useSettings && this._config.autoRender)) ? this.initVsAvailableIn() : Promise.resolve(),
      (!useSettings || (useSettings && this._config.autoRender)) ? this.initVsAddToCart() : Promise.resolve(),
      (!useSettings || (useSettings && this._config.autoRender)) ? this.initVsProductItems() : Promise.resolve(),
      (!useSettings || (useSettings && this._config.autoRender)) ? this.initVsProductCatalogs() : Promise.resolve(),
      (!useSettings || (useSettings && this._config.autoRender)) ? this.initVsLogins() : Promise.resolve(),
      (!useSettings || (useSettings && this._config.autoRender)) ? this.initVsClubRegistrations() : Promise.resolve(),
    ])
      .finally(() => {
        this.rendered = true
      })
  }

  async render () {
    if (this.isDebug()) {
      console.info('Vinoshipper :: Render')
    }
    return this.renderInit(false)
  }

  async renderItem (item : HTMLElement) {
    if (item.classList.contains('vs-login')) {
      return this.initVsLoginsItem(item)
    } else if (item.classList.contains('vs-add-to-cart')) {
      return this.initVsAddToCartItem(item)
    } else if (item.classList.contains('vs-product-item')) {
      return this.initVsProductItemsItem(item)
    } else if (item.classList.contains('vs-club-registration')) {
      return this.initVsClubRegistrationsItem(item)
    } else if (item.classList.contains('vs-announcement') || item.classList.contains('vs-announcements')) {
      return this.initVsAnnouncementItem(item)
    } else if (item.classList.contains('vs-available')) {
      return this.initVsAvailableInItem(item)
    } else if (item.classList.contains('.vs-product-list') || item.classList.contains('vs-products')) {
      if (this._config.productCatalogV3) {
        return this.initVsProductCatalogsV3Item(item)
      } else {
        return this.initVsProductCatalogsItem(item)
      }
    } else {
      return Promise.resolve()
    }
  }

  private async cartInit () {
    let tempCartId = null
    const tempCartIdCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('vsCartId='))
      ?.split('=')[1]

    if (this._cartId) {
      tempCartId = this._cartId
    } else if (tempCartIdCookie) {
      tempCartId = tempCartIdCookie
    }
    return this.initCartData(tempCartId)
  }

  private async cartIdSet (cartId : string) {
    this._cartId = cartId
    const expireDate = new Date()
    expireDate.setDate(expireDate.getDate() + 31)
    document.cookie = `vsCartId=${cartId}; Path=/; Expires=${expireDate.toUTCString()}; SameSite=None; Secure`
  }

  private async cartIdSetForce (cartId : string) {
    this.cartIdSet(cartId)
    return this.initCartData(this._cartId)
  }

  private onMessage (message : MessageEvent<VinoshipperIframeMessage>) {

    // ALL Messages must start with 'vinoshipper:' to listen in on.
    if (message.data.action?.includes('vinoshipper:')) {

      if (this.isDebug() && message.data.action !== VinoshipperIframeMessageActions.RESIZE_HEIGHT) {
        // Debugging
        console.info('onMessage()', message.data)
      }

      if (message.data.action === VinoshipperIframeMessageActions.VS_HREF) {
        window.location.href = message.data.value
      }

      if (message.data.action === VinoshipperIframeMessageActions.VS_CARTID) {
        this.cartIdSetForce(message.data.value)
      }

      if (message.data.action === VinoshipperIframeMessageActions.PRODUCT_ADD) {
        this.onProductAdd(message.data.value.id, message.data.value.qty, {
          metaFields: message.data.value.metaFields ?? undefined,
        })
      }

      if (
        message.data.instanceId === 'cart' ||
        message.data.instanceId === 'add_to_cart'
      ) {
        this._cartObj?.onMessage(message)
        return
      }

      const updateElements = this.renderedObjects.filter((object) => {
        return (object._instanceId === message.data.instanceId) || message.data.instanceId === 'client'
      })
      updateElements.forEach((item) => {
        item.onMessage(message)
      })

      // Send Custom event to window.
      const dispatchEvent = new CustomEvent(
        message.data.action,
        {
          cancelable: false,
          bubbles: true,
          detail: message.data
        }
      )
      window.dispatchEvent(dispatchEvent)
    }
  }

  private async initVsClubRegistrationsItem (item : HTMLElement) {
    try {
      if (this.producerId === 'catalog') {
        return true
      }
      const {
        default: VSClubRegistration
      } = await import(
        /* webpackChunkName: 'vs-club-registration' */
        /* webpackPrefetch: true */
        '../modules/club-registration'
      )

      const object = new VSClubRegistration(item)
      this.renderedObjects.push(object)

    } catch {
      return Error('VSClubRegistration could not load.')
    }
  }

  private async initVsClubRegistrations () {
    const clubRegistrations = window.document.querySelectorAll('.vs-club-registration') as NodeListOf<HTMLElement>
    if (clubRegistrations.length > 0) {

      clubRegistrations.forEach((item) => {
        this.initVsClubRegistrationsItem(item)
      })

    } else if (this.isDebug()) {
      console.info('VSClubRegistration not needed.')
    }
  }

  private VsLoginsCanUse () : boolean {
    let returnVar = false
    this._vsLoginAllowDomains.forEach((test) => {
      if (window.location.hostname.indexOf(test) > -1) {
        returnVar = true
      }
    })
    return returnVar
  }

  private async initVsLoginsItem (item : HTMLElement) {
    if (item.getAttribute(`${this.dataPrefix}rendered`) === 'true') {
      return Promise.resolve()
    } else {
      try {
        if (this.VsLoginsCanUse()) {
          const {
            default: VSLogin
          } = await import(
            /* webpackChunkName: 'vs-login' */
            /* webpackPrefetch: true */
            '../modules/login'
          )

          const object = new VSLogin(item)
          this.renderedObjects.push(object)
        } else {
          throw new Error('VSLogin not allowed on this Domain.')
        }
      } catch {
        return Error('VSLogin could not load.')
      }
    }
  }

  private async initVsLogins () {
    const loginDOMs = window.document.querySelectorAll('.vs-login') as NodeListOf<HTMLElement>
    if (loginDOMs.length > 0) {
      loginDOMs.forEach((item) => {
        this.initVsLoginsItem(item)
      })
    } else if (this.isDebug()) {
      console.info('VSLogin not needed.')
    }
  }

  /**
   * @deprecated
   */
  private async initVsProductCatalogsV3Item (item : HTMLElement) {
    if (item.getAttribute(`${this.dataPrefix}rendered`) === 'true') {
      return Promise.resolve()
    } else {
      try {
        if (this.producerId === 'catalog') {
          return Promise.resolve()
        }
        const {
          default: VSProductCatalogV3
        } = await import(
          /* webpackChunkName: 'vs-product-v3' */
          /* webpackPrefetch: true */
          '../modules/products-v3'
        )
        if (item.getAttribute(`${this.dataPrefix}rendered`) !== 'true') {
          const object = new VSProductCatalogV3(item, {
            v3CssProductList: this._config.v3CssProductList ? this._config.v3CssProductList : null,
          })
          this.renderedObjects.push(object)
        }

      } catch {
        return Error('VSProductCatalogV3 could not load.')
      }
    }
  }

  private async initVsProductCatalogsItem (item : HTMLElement) {
    if (item.getAttribute(`${this.dataPrefix}rendered`) === 'true') {
      return Promise.resolve()
    } else {
      try {
        if (this.producerId === 'catalog') {
          return Promise.resolve()
        }
        const {
          default: VSProductCatalog
        } = await import(
          /* webpackChunkName: 'vs-product' */
          /* webpackPrefetch: true */
          '../modules/products'
        )
        if (item.getAttribute(`${this.dataPrefix}rendered`) !== 'true') {
          const object = new VSProductCatalog(item, {
            loadStyle: this._config.productCatalogStyle,
            availableIn: this._config.productCatalogAvailable,
            announcement: this._config.productCatalogAnnouncement,
            announcementStyle: this._config.announcementStyle,
            tooltips: (typeof this._config.availableInTooltips === 'boolean') ? this._config.availableInTooltips : this._config.tooltips,
          })
          this.renderedObjects.push(object)
        }

      } catch {
        return Error('VSProductCatalog could not load.')
      }
    }
  }

  private async initVsProductCatalogs () {
    const productCatalogs = window.document.querySelectorAll('.vs-product-list, .vs-products') as NodeListOf<HTMLElement>
    if (productCatalogs.length > 0 && !this._config.productCatalogV3) {
      productCatalogs.forEach((item) => {
        this.initVsProductCatalogsItem(item)
      })
    } else if (productCatalogs.length > 0 && this._config.productCatalogV3) {
      productCatalogs.forEach((item) => {
        this.initVsProductCatalogsV3Item(item)
      })
    } else if (this.isDebug()) {
      console.info('VSProductCatalog not needed.')
    }
  }

  private async initVsProductItemsItem (item : HTMLElement) {
    if (item.getAttribute(`${this.dataPrefix}rendered`) === 'true') {
      return Promise.resolve()
    } else {
      try {
        const {
          default: VSProductItem
        } = await import(
          /* webpackChunkName: 'vs-product-item' */
          /* webpackPrefetch: true */
          '../modules/product-item'
        )
        if (item.getAttribute(`${this.dataPrefix}rendered`) !== 'true') {
          const object = new VSProductItem(item, {
            loadStyle: this._config.productCatalogStyle,
            productImage: this._config.productItemImage,
          })
          this.renderedObjects.push(object)
        }
      } catch {
        return new Error('VSProductItems could not load.')
      }
    }
  }

  private async initVsProductItems () {
    const productItems = window.document.querySelectorAll('.vs-product-item') as NodeListOf<HTMLElement>
    if (productItems.length > 0) {
      productItems.forEach((item) => {
        this.initVsProductItemsItem(item)
      })
    } else if (this.isDebug()) {
      console.info('VSProductItems not needed.')
    }
  }

  private async initVsAvailableInItem (item: HTMLElement) {
    if (item.getAttribute(`${this.dataPrefix}rendered`) === 'true') {
      return Promise.resolve()
    } else {
      try {
        if (this.producerId === 'catalog') {
          return true
        }
        const {
          default: VSAvailableIn
        } = await import(
          /* webpackChunkName: 'vs-available' */
          /* webpackPrefetch: true */
          '../modules/available'
        )
        if (item.getAttribute(`${this.dataPrefix}rendered`) !== 'true') {
          const object = new VSAvailableIn(item, {
            loadStyle: this._config.availableInStyle,
            tooltips: (typeof this._config.availableInTooltips === 'boolean') ? this._config.availableInTooltips : this._config.tooltips,
          })
          this.renderedObjects.push(object)
        }
      } catch {
        return Error('VSAvailableIn could not load.')
      }
    }
  }

  private async initVsAvailableIn () {
    const availableInDoms = window.document.querySelectorAll('.vs-available') as NodeListOf<HTMLElement>
    if (availableInDoms.length > 0) {
      availableInDoms.forEach((item) => {
        this.initVsAvailableInItem(item)
      })
    } else if (this.isDebug()) {
      console.info('VSAvailableIn not needed.')
    }
  }

  private async initVsAnnouncementItem (item: HTMLElement) {
    if (item.getAttribute(`${this.dataPrefix}rendered`) === 'true') {
      return Promise.resolve()
    } else {
      try {
        const {
          default: VSAnnouncement
        } = await import(
          /* webpackChunkName: 'vs-announcement' */
          /* webpackPrefetch: true */
          '../modules/announcement'
        )
        if (item.getAttribute(`${this.dataPrefix}rendered`) !== 'true') {
          const object = new VSAnnouncement(item, {
            loadStyle: this._config.announcementStyle,
          })
          this.renderedObjects.push(object)
        }
      } catch {
        return Error('VSAnnouncement could not load.')
      }
    }
  }

  private async initVsAnnouncement () {
    const itemDoms = window.document.querySelectorAll('.vs-announcement') as NodeListOf<HTMLElement>
    if (itemDoms.length > 0) {
      itemDoms.forEach((item) => {
        this.initVsAnnouncementItem(item)
      })
    } else if (this.isDebug()) {
      console.info('VSAnnouncement not needed.')
    }
  }

  private async initVsAddToCartItem (item: HTMLElement) {
    if (item.getAttribute(`${this.dataPrefix}rendered`) === 'true') {
      return Promise.resolve()
    } else {
      try {
        const {
          default: VSAddToCart
        } = await import(
          /* webpackChunkName: 'vs-add-to-cart' */
          /* webpackPrefetch: true */
          '../modules/add'
        )
        if (item.getAttribute(`${this.dataPrefix}rendered`) !== 'true') {
          const object = new VSAddToCart(item, {
            loadStyle: this._config.addToCartStyle,
          })
          this.renderedObjects.push(object)
        }

      } catch {
        return Error('VSAddToCart could not load.')
      }
    }
  }

  private async initVsAddToCart () {
    const productLists = window.document.querySelectorAll('.vs-add-to-cart') as NodeListOf<HTMLElement>
    if (productLists.length > 0) {
      return this.initVsAddToCartModules(productLists)
    } else {
      return Promise.resolve()
    }
  }

  private async initVsAddToCartModules (productLists : NodeListOf<HTMLElement>) {
    try {
      productLists.forEach((item) => {
        this.initVsAddToCartItem(item)
      })
    } catch {
      return Error('VSAddToCart could not load.')
    }
  }

  private async initVsCart () {
    if (this._cartObj) {
      if (this.isDebug()) {
        console.info('VSCart already rendered.')
      }
      return
    }
    try {
      const {
        default: VSCart
      } = await import(
        /* webpackChunkName: 'vs-cart' */
        /* webpackPrefetch: true */
        '../modules/cart'
      )
      this._cartObj = new VSCart({
        cartButton: this._config.cartButton,
        position: this._config.cartPosition,
      })
    } catch {
      return Error('VSCart could not load.')
    }
  }

  getCart () : Cart | null {
    return this._cart
  }

  getCartCheckout () : URL {
    const checkoutHref = new URL('/cart', window.Vinoshipper.getServer())
    if (this._cart?.cartId) {
      checkoutHref.searchParams.append('cartId', this._cart.cartId)
    }
    return checkoutHref
  }

  cartOpen () : void {
    return this._cartObj?.onOpen()
  }

  cartClose () : void {
    return this._cartObj?.onClose()
  }

  getId () : number | null {
    if (this.producerId === 'catalog') {
      return null
    } else {
      return this.producerId
    }
  }

  getServer (includeProtocol = true) : string | undefined {
    let returnServer = this._config.vsServer
    if (
      window.location.hostname &&
      !window.location.hostname.includes('partners.zlminc.dev') &&
      !window.location.hostname.includes('partners-2.zlminc.dev') &&
      (
        window.location.hostname.indexOf('zlminc.com') > -1 ||
        window.location.hostname.indexOf('zlminc.dev') > -1
      )
    ) {
      returnServer = `https://${window.location.hostname}`
    }
    if (!includeProtocol) {
      returnServer = returnServer?.replace('https://', '')
    }
    return returnServer
  }

  getTheme (withPrefix = false) : string | null {
    if (withPrefix && this._config.theme) {
      return `vs-theme-${this._config.theme}`
    } else {
      return this._config.theme ?? null
    }
  }

  isThemeDark () : boolean {
    return this._config.theme?.endsWith('dark') ?? false
  }

  getIFrameStyle () : string | null {
    return this._config.iframeStyle ?? null
  }

  private updateCart (cart : Cart) {
    this._cart = cart
    this.cartIdSet(this._cart.cartId)
    if (!this._config.skipAnalytics) {
      this.analytics?.pixelsUpdate(this._cart.pixels)
    }
  }

  /**
   * Returns TRUE if the core Vinoshipper library is loaded.
   */
  isLoaded () : boolean {
    return this.loaded
  }

  /**
   * Returns TRUE if the configuration is complete.
   */
  isConfigured () : boolean {
    return this.configured
  }

  /**
   * Returns TRUE if the first rendering is completed.
   */
  isRendered () : boolean {
    return this.rendered
  }

  isDebug () : boolean {
    if (this._config.debug) {
      return this._config.debug
    } else {
      return false
    }
  }

  setDebug (debug = true) {
    if (debug) {
      this._config.debug = true
    } else {
      this._config.debug = false
    }
  }

  async getLinkParams (url : URL, includeRet = true) : Promise<URL> {
    const timeoutPromise = new Promise<URL>((resolve) => {
      setTimeout(resolve, 800, url);
    })
    return Promise.race([this._getLinkParamsAnalytics(url, includeRet), timeoutPromise])
      .then((results) => {
        return this._getLinkParamsConfig(results)
      })
      .catch((results) => {
        return this._getLinkParamsConfig(results)
      })
  }

  private async _getLinkParamsAnalytics (url : URL, includeRet = true) : Promise<URL> {
    if (this.analytics) {
      return this.analytics.linkGenerate(url, includeRet)
        .then((results) => {
          return results
        })
        .catch(() => {
          return url
        })
    } else {
      return Promise.resolve(url)
    }
  }

  private async _getLinkParamsConfig (url : URL) : Promise<URL> {
    if (typeof this._config.cartUrlParams === 'function') {
      return this._config.cartUrlParams()
        .then((results) => {
          if (typeof results === 'object' && results.length > 0) {
            if (this.isDebug()) {
              console.group('Vinoshipper:getLinkParams()')
            }
            results.forEach((item) => {
              if (typeof item.key === 'string') {
                if (this.isDebug()) {
                  console.info('Adding Checkout Search Param:', item.key, item.value.toString())
                }
                url.searchParams.append(item.key, item.value.toString())
              }
            })
            if (this.isDebug()) {
              console.groupEnd()
            }
          }
          return Promise.resolve(url)
        })
        .catch(() => {
          return Promise.reject(url)
        })
    } else {
      return Promise.resolve(url)
    }
  }

  getDeliveryOptions () {
    return this._deliveryOptions
  }

  /**
   * Gets the announcement string, if defined.
   *
   * String will be capable of Markdown. You will need to sanitize and process
   * the string as Markdown to properly display formatting.
   */
  getAnnouncement () : string | null {
    return this._announcement
  }

  /**
   * Adds or Updates a product from the cart.
   *
   * @param {number} productId - The ID of the product to add.
   * @param {number} qty - The number of product to add to the cart. If options.update is true, then it will overwrite the quantity.
   * @param {VSCartOnProductAddOptions | undefined} options - Sets optional options for updating the product.
   * @return {Promise<void>}
   * @memberof Vinoshipper
  */
  async onProductAdd (productId : number, qty : number = 1, options : VSCartOnProductAddOptions = {}) : Promise<void> {

    if (this.isDebug()) {
      console.group('Vinoshipper:onProductAdd()')
      console.info('Product Id:', productId)
      console.info('Quantity:', qty)
      console.info('Options:', options)
      console.groupEnd()
    }

    if (!this._cartId) {
      try {
        this.initCartData(null)
          .then(() => {
            return this.onProductAddPrivate(productId, qty, options)
          })
      } catch {
        throw new Error('No Cart ID')
      }
    } else {
      return this.onProductAddPrivate(productId, qty, options)
    }

  }

  private async onProductAddPrivate (productId : number, qty = 1, options : VSCartOnProductAddOptions | undefined = undefined) : Promise<void> {
    let cartUrl = new URL(`/api/v3/cart/${this._cartId}/item`, window.Vinoshipper.getServer())
    let cartMethod = 'POST'
    let correctedQty = Math.max(qty, 1)
    if (options?.update) {
      cartUrl = new URL(`/api/v3/cart/${this._cartId}/item/${productId}`, window.Vinoshipper.getServer())
      cartMethod = 'PUT'
      correctedQty = qty
    }
    let metaFields : Record<string, string> = {}
    if (options?.metaFields) {
      metaFields = options.metaFields
    }
    // If we don't define vsProgramOffering in metaFields, define the global.
    if (!metaFields['vsProgramOffering'] && this._config.vsProgramOffering) {
      metaFields['vsProgramOffering'] = this._config.vsProgramOffering
    }

    return fetch(cartUrl, {
      method: cartMethod,
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: productId,
        qty: correctedQty,
        metaFields: metaFields ?? undefined,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Vinoshipper: Cart Add Product response was not OK')
        }
        return response.json()
      })
      .then((response : Cart) => {
        this.updateCart(response)
        const message : VinoshipperIframeMessage = {
          instanceId: 'cart',
          action: VinoshipperIframeMessageActions.CART_UPDATE,
          value: response,
        }
        const newMessage : MessageEvent = new MessageEvent(
          VinoshipperIframeMessageActions.CART_UPDATE,
          {
            data: message
          }
        )
        this._cartObj?.onMessage(newMessage)
        return this.cartOpen()
      })
  }

  /**
   * Removes a product completely from the cart.
   *
   * @param {LineItem} lineItem - The LineItem to remove.
   * @return {Promise<void>}
   * @memberof Vinoshipper
  */
  async onProductRemove (lineItem : LineItem): Promise<void> {
    const message = new CustomEvent(
      VinoshipperInjectorMessageActions.VS_PRODUCT_REMOVE,
      {
        cancelable: false,
        bubbles: true,
        detail: {
          instanceId: 'cart',
          action: VinoshipperInjectorMessageActions.VS_PRODUCT_REMOVE,
          value: lineItem,
        },
      }
    )
    window.dispatchEvent(message)
    return this.onProductAdd(lineItem.id, 0, {
      update: true,
    })
  }
  /**
   * Take a SubmitEvent and creates an onProductAdd() call.
   *
   * @param {SubmitEvent} event
   * @return {*}  {(Promise<void | Error>)}
   * @memberof Vinoshipper
  */
  async onProductAddFormSubmit(event: SubmitEvent) : Promise<void | Error> {
    event.preventDefault()

    const target = event.target as HTMLFormElement
    const formData = new FormData(target)

    if (this.isDebug()) {
      console.group('Vinoshipper :: onProductAddFormSubmit()')
    }

    let productId = null
    let qty = null

    const tempProductId = formData.get('id')
    const tempProductQty = formData.get('qty')
    const tempProductQuantity = formData.get('quantity')

    if (typeof tempProductId === 'string') {
      productId = parseInt(tempProductId)
    } else if (typeof tempProductId === 'number') {
      productId = tempProductId
    }

    if (typeof tempProductQty === 'string') {
      qty = parseInt(tempProductQty)
    } else if (typeof tempProductQuantity === 'string') {
      qty = parseInt(tempProductQuantity)
    } else {
      qty = 1
    }

    const returnUrl = formData.get('returnUrl')?.toString() ?? undefined

    if (this.isDebug()) {
      console.info('Product ID:', productId)
      console.info('Qty:', qty)
      console.info('Return URL:', returnUrl)
      console.groupEnd()
    }

    if (!productId) {
      return Error('Vinoshipper :: onProductAddFormSubmit :: Product ID not defined.')
    }

    try {
      window.postMessage({
        action: VinoshipperIframeMessageActions.PRODUCT_ADD,
        value: {
          id: productId,
          qty: qty,
          // product: data,
        },
      })
    } catch {
      return Error('Vinoshipper :: onProductAddFormSubmit could not load.')
    }
  }

  /**
   * Returns the value of a named setting, based on the setting stack order.
   *
   * Element > Initialized Settings > Defaults
   *
   * @param {string} name - The name of the setting (do NOT include "data-").
   * @param {HTMLElement} [element] - Optional target element.
   * @return {*} - Return type depends on type of variable.
   * @memberof Vinoshipper
  */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settingsGet (name : string, defaultReturn: any = null, element : Element | HTMLElement | undefined) : any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let processedReturn : any = defaultReturn
    if (element && element.hasAttribute(`${this.dataPrefix}${name}`)) {
      processedReturn = element.getAttribute(`${this.dataPrefix}${name}`)
    } else if (this.settingsHasGlobal(name)) {
      processedReturn = this.settingsHasGlobal(name)
    }

    // Special case based on variable name
    if (name === 'theme') {
      processedReturn = this.themeNormalize(processedReturn)
    }

    return processedReturn
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private settingsHasGlobal (name: string) : any | undefined {
    const needle = Object.entries(this._config).find(([key]) => {
      if (key === name) {
        return true
      }
    })
    if (needle?.length) {
      return needle[1]
    } else {
      return undefined
    }
  }

  destroy (instanceId : string | null = null) {
    this.renderedObjects.forEach((item) => {
      if (
        (item._instanceId === instanceId || instanceId == null) &&
        item.destroy
      ) {
        item.destroy()
      }
    })
    if (!instanceId) {
      this._cartObj?.destroy()
    }
  }

  private errorVinoshipperAlreadyLoaded () {
    const title = 'Vinoshipper: More than one init() call'
    const message = '\n\nThis website is calling the Vinoshipper Injector init() function more than once.\n\nPlease remove all instances of Vinoshipper Injector V4 except for one. For more information, visit:\nhttps://vinoshipper.freshdesk.com/support/solutions/articles/9000203795-integration-resources'

    console.error('%c%s%c%s', this._styleHeader, title, this._styleDescription, message)
  }

    /**
   * Returns the value of a named setting, based on the setting stack order.
   *
   * Element > Initialized Settings > Defaults
   *
   * @memberof Vinoshipper
  */
  previewProductListTesting (useV3 = false) {
    this._config.productCatalogV3 = useV3
    const productLists = window.document.querySelectorAll('.vs-product-list') as NodeListOf<HTMLElement>
    productLists.forEach((item) => {
      item.setAttribute(`${this.dataPrefix}rendered`, 'false')
    })
    this.render()
  }

  /**
   * Normalizes themes to supported theme names.
  */
  themeNormalize (inputTheme : string | null) : vsThemeNames {
    if (inputTheme?.toLowerCase().includes('-light')) {
      return inputTheme.toLowerCase().replace('-light', '') as vsThemeNames
    } else if (inputTheme && inputTheme?.toLowerCase() !== 'light') {
      return inputTheme.toLowerCase() as vsThemeNames
    } else {
      return null
    }
  }

}
